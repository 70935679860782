const GreenCheck = (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2083 14.0625L18.3229 25.9375L13.375 20.5398M39.5 20C39.5 30.4934 30.9934 39 20.5 39C10.0066 39 1.5 30.4934 1.5 20C1.5 9.50659 10.0066 1 20.5 1C30.9934 1 39.5 9.50659 39.5 20Z"
      stroke="#13BD68"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default GreenCheck;
