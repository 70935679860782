const Ticket = (...props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4199 10L14.3299 4.91L12.7999 4C12.5061 4.16422 12.1826 4.26863 11.8483 4.30723C11.5139 4.34582 11.1751 4.31783 10.8516 4.22487C10.5281 4.1319 10.2262 3.9758 9.96331 3.76558C9.70042 3.55535 9.48175 3.29516 9.31992 3C9.13071 2.65175 9.0214 2.26575 8.99992 1.87V1.82L7.47992 1L4.57992 6.1L1.66992 11.14L3.18992 12C3.55242 11.812 3.95189 11.7061 4.35992 11.69C4.69484 11.678 5.02885 11.7321 5.34285 11.8492C5.65684 11.9664 5.94466 12.1443 6.18985 12.3728C6.43504 12.6012 6.63279 12.8758 6.77179 13.1807C6.91079 13.4857 6.98831 13.8151 6.99992 14.15C6.99992 14.15 6.99992 14.15 6.99992 14.15L8.52992 15.03L11.4199 10Z"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3996 8.23005L5.59961 4.30005"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Ticket;
